let settings = {
  dsn: process.env.SENTRY_DSN,
  environment: "production", // values: production, deploy-preview, branch-deploy, dev
  release: `${process.env.SENTRY_RELEASE_PREFIX}${"bea10db5786197663c11db4c430377f2c1dfe000"}`
};

// Local dev via `netlify dev`
if (process.env.NETLIFY_DEV === "true") {
  settings.release = `${settings.release}-dev`;
}
// Local dev via `netlify serve` or `netlify build`
else if (process.env.NETLIFY_LOCAL === "true") {
  settings.release = `${settings.release}-local`;
  settings.environment = "dev";
}

export const SentrySettings = {
  ...settings
};
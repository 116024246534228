import style from "./style.scss";

const Loader = () => {
  return (
    <div class={style.loader}>
      <div class={style.dots}>
        <div class={style.dot} />
      </div>
    </div>
  );
};

export default Loader;

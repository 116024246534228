import style from "./style.scss";
import { useGlobalState } from "../global-state";
import Loader from "../loader";

import MagicCarpetIcon from "../../icons/lifts/magic-carpet.svg";
import Chairlift2Icon from "../../icons/lifts/chairlift-2.svg";
import Chairlift3Icon from "../../icons/lifts/chairlift-3.svg";
import Chairlift4Icon from "../../icons/lifts/chairlift-4.svg";
import DetachableChairlift4Icon from "../../icons/lifts/detachable-chairlift-4.svg";

const liftIcons = {
  "magic-carpet": MagicCarpetIcon,
  "chairlift-2": Chairlift2Icon,
  "chairlift-3": Chairlift3Icon,
  "chairlift-4": Chairlift4Icon,
  "detachable-chairlift-4": DetachableChairlift4Icon,
};

const formatTimeAmPm = (time) => {
  const [hour, minute] = time.split(":");
  const hourInt = parseInt(hour, 10);
  const ampm = hourInt >= 12 ? "pm" : "am";
  const hourFormatted = hourInt % 12 || 12; // Converts '0' or '12' to '12'
  return (
    <span>
      {hourFormatted}
      {`:${minute}`}
      <span class={style.ampm}>{ampm}</span>
    </span>
  );
};

const Lifts = () => {
  const { snowConditions } = useGlobalState();

  return (
    <div class={style.lifts}>
      {snowConditions.areas ? null : <h1>Lifts Status</h1>}
      <div class={style.content}>
        {snowConditions.areas ? (
          <ul>
            {snowConditions.areas.map((area) => (
              <li key={area.name}>
                <h2>{area.name}</h2>
                <ul>
                  {area.lifts.map((lift) => {
                    const LiftIcon = liftIcons[lift.liftIcon];

                    const status = lift.status.toLowerCase();
                    let statusClass = "";
                    if (status === "open") {
                      statusClass = style.open;
                    } else if (status === "closed") {
                      statusClass = style.closed;
                    }

                    return (
                      <li key={lift.name} class={statusClass}>
                        <div>
                          <div class={style.icon}>
                            {LiftIcon ? (
                              <LiftIcon alt={`${lift.type} - ${lift.status}`} />
                            ) : null}
                          </div>
                          <h3>{lift.name}</h3>
                          {lift.openTime && lift.closeTime ? (
                            <div class={style.time}>
                              {formatTimeAmPm(lift.openTime)}-
                              {formatTimeAmPm(lift.closeTime)}
                            </div>
                          ) : (
                            <div />
                          )}
                        </div>
                        <p>{lift.comment}</p>
                      </li>
                    );
                  })}
                </ul>
              </li>
            ))}
          </ul>
        ) : (
          <div class={style.loading}>
            <Loader />
          </div>
        )}
      </div>
      <footer>
        <a
          href="https://www.summitatsnoqualmie.com/mountain-report#lift-status"
          target="_blank"
          rel="noreferrer"
        >
          View on Summit Website
        </a>
      </footer>
    </div>
  );
};

export default Lifts;

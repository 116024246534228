import style from "./style.scss";

const Emergency = () => {
  return (
    <div class={style.emergency}>
      <h1>Emergency Contacts</h1>

      <div class={style.content}>
        <h2>Emergency Services</h2>
        <p>
          <a href="tel:911">911</a>
        </p>

        <h2>Ski Patrol</h2>
        <p>
          <a href="tel:+14254346794">425-434-6794</a>
        </p>

        <h2>Security</h2>
        <p>
          <a href="tel:+14254346747">425-434-6747</a>
        </p>

        <h2>
          Radio Channels <span class={style.unimportant}>(unmonitored)</span>
        </h2>

        <h3>2-7</h3>
        <p>Alpental / Snow Lake</p>

        <h3>3-7</h3>
        <p>Kendall</p>

        <h3>6-7</h3>
        <p>Hyak</p>
      </div>
    </div>
  );
};

export default Emergency;

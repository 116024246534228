import style from "./style.scss";
import { useGlobalState } from "../global-state";
import Loader from "../loader";

const isClosed = (cellData) => {
  return cellData && cellData.status && cellData.status === "CLOSED";
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const day = dayNames[date.getUTCDay()];
  const month = date.getUTCMonth() + 1; // getUTCMonth() is zero-based
  const dayOfMonth = date.getUTCDate();
  return (
    <span>
      <span class={style.day}>{day}</span> {month}/{dayOfMonth}
    </span>
  );
};

const formatTimeAmPm = (time) => {
  const [hour, minute] = time.split(":");
  const hourInt = parseInt(hour, 10);
  const ampm = hourInt >= 12 ? "pm" : "am";
  const hourFormatted = hourInt % 12 || 12; // Converts '0' or '12' to '12'
  return (
    <span>
      {hourFormatted}
      {`:${minute}`}
      <span class={style.ampm}>{ampm}</span>
    </span>
  );
};

const renderCellContent = (cellData) => {
  let result;
  if (!cellData) {
    result = "";
  } else if (typeof cellData === "string") {
    result = cellData;
  } else if (cellData.status === "OPEN") {
    result = (
      <span>
        <span class={style.nowrap}>{formatTimeAmPm(cellData.openTime)}-</span>
        <span class={style.nowrap}>{formatTimeAmPm(cellData.closeTime)}</span>
      </span>
    );
  } else if (cellData.status === "CLOSED") {
    result = "Closed";
  } else {
    result = cellData.status;
  }
  return result;
};

const Table = ({ columns, data }) => {
  //console.log(columns, data);

  return (
    <div class={style.fixedTable}>
      <table>
        <thead>
          <tr>
            {columns.map((column) => (
              <th
                key={column.accessor}
                class={column.sticky ? style.sticky : ""}
              >
                {column.isDate ? formatDate(column.label) : column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody class={columns[0].accessor === "area" ? style.noColWrap : ""}>
          {data.map((item) => (
            <tr key={item.id}>
              {columns.map((column) =>
                column.sticky ? (
                  <th key={column.accessor} class={style.sticky}>
                    {formatDate(item[column.accessor])}
                  </th>
                ) : (
                  <td
                    key={column.accessor}
                    class={isClosed(item[column.accessor]) ? style.closed : ""}
                  >
                    {renderCellContent(item[column.accessor])}
                  </td>
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Calendar = () => {
  const { snowConditions } = useGlobalState();

  return (
    <div class={style.calendar}>
      <h1>Hours of Operation</h1>
      <div class={style.content}>
        {snowConditions.hours ? (
          <Table
            columns={snowConditions.hours.columns}
            data={snowConditions.hours.data}
          />
        ) : (
          <div class={style.loading}>
            <Loader />
          </div>
        )}
      </div>
      <footer>
        <a
          href="https://www.summitatsnoqualmie.com/hours"
          target="_blank"
          rel="noreferrer"
        >
          View Full Calendar
        </a>
      </footer>
    </div>
  );
};

export default Calendar;

import { Fragment } from "preact";
import Webcams from "./webcams";
import Wsdot from "./wsdot";
import Snowfall from "./snowfall";
import LinkList from "./link-list";

const Layout = () => (
  <Fragment>
    <Webcams />
    <Wsdot />
    <Snowfall />
    <LinkList
      links={[
        {
          link: "https://forecast.weather.gov/MapClick.php?CityName=Snoqualmie+Pass&state=WA&site=SEW&textField1=47.4226&textField2=-121.411&e=0#.WGv9sPkrIUE",
          img: "/logos/noaa_logo_circle_72x72.svg",
          newWindow: true,
          text: "NOAA Forecast",
        },
        {
          link: "https://www.nwac.us/mountain-weather-forecast/current/",
          img: "/logos/nwac.png",
          newWindow: true,
          text: "NWAC Forecast",
        },
        {
          link: "https://www.summitatsnoqualmie.com/conditions",
          img: "/logos/snoqualmie.png",
          newWindow: true,
          text: "Summit at Snoqualmie",
        },
        {
          link: "https://parkatthesummit.vpcpay.com/permits/dashboard",
          img: "/logos/parking.svg",
          newWindow: true,
          text: "Summit Parking",
        },
        {
          link: "https://www.nwac.us/weatherdata/alpental/now/",
          img: "/logos/nwac.png",
          newWindow: true,
          text: "NWAC Alpental",
        },
        {
          link: "https://www.nwac.us/weatherdata/snoqualmiepass/now/",
          img: "/logos/nwac.png",
          newWindow: true,
          text: "NWAC Snoqualmie Pass",
        },
        {
          link: "https://wsdot.com/travel/real-time/mountainpasses/snoqualmie",
          img: "/logos/wsdot.png",
          newWindow: true,
          text: "WSDOT",
        },
      ]}
    />
  </Fragment>
);

export default Layout;
